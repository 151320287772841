<template>
  <div class="row">
    <!-- Table Heading -->
    <h1 class="table-heading no-p-h" style="margin-bottom: 15px">
      {{ $t("worst_sites.title") }}
      <button class="btn btn-primary btn-sm float-end" @click="exportReport">
        <span
          v-if="exporting"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Export
      </button>
    </h1>

    <!-- Period -->
    <div class="form-group col-xl-3 m-b-sm form-heading">
      <select
        v-model="period"
        class="form-select"
        @change="() => getWorstSites()"
      >
        <option value="day" selected>{{ $t("dashboard.todays_data") }}</option>
        <option value="week">{{ $t("dashboard.weeks_data") }}</option>
        <option value="month">{{ $t("dashboard.months_data") }}</option>
        <option value="year">{{ $t("dashboard.years_data") }}</option>
      </select>
    </div>

    <!-- Date -->
    <div class="row">
      <div class="col-4">
        <button
          class="btn btn-primary btn-sm"
          style="margin-top: 15px"
          @click="changeDifference(-1)"
        >
          Previous {{ period }}
        </button>
      </div>
      <div class="col-4">
        <h4 class="sub-title text-center p-v-sm no-p-h">{{ title }}</h4>
      </div>
      <div class="col-4">
        <button
          class="btn btn-primary btn-sm float-end"
          style="margin-top: 15px"
          @click="changeDifference(+1)"
        >
          Next {{ period }}
        </button>
      </div>
    </div>

    <!-- Worst Sites Table  -->
    <table class="table table-striped m-b-lg" id="worstsites">
      <thead>
        <tr>
          <th scope="col">{{ $t("sites.name").toUpperCase() }}</th>
          <th scope="col">{{ $t("worst_sites.money_lost").toUpperCase() }}</th>
          <th scope="col">{{ $t("worst_sites.recovered").toUpperCase() }}</th>
          <th scope="col">{{ $t("worst_sites.worst_pump").toUpperCase() }}</th>
        </tr>
      </thead>

      <!-- loading -->
      <td colspan="3" v-if="loading" class="parent-div">
        <div
          class="spinner-border center-div"
          style="height: 4rem; width: 4rem"
          role="status"
        >
          <span class="visually-hidden">{{ $t("events.loading") }}...</span>
        </div>
      </td>

      <tbody v-else>
        <tr v-show="worstSites.length < 1">
          <td colspan="6" class="no-data-container">No data to show</td>
        </tr>
        <tr
          v-for="site in worstSites"
          :key="'site-' + site.id"
          style="vertical-align: middle"
        >
          <td>
            <span v-show="site.siteReference">({{ site.siteReference }})</span>
            {{ site.name.charAt(0).toUpperCase() + site.name.slice(1) }}
          </td>
          <td>
            <span v-if="$i18n.locale === 'en-GB'"> £ </span>
            {{ site.lost.toLocaleString() }}
          </td>
          <td>
            <span v-if="$i18n.locale === 'en-GB'"> £ </span>
            {{ site.paid.toLocaleString() }}
          </td>
          <td>{{ site.worstPump === -1 ? "No Pump" : site.worstPump }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MessageCard from "../../../components/MessageCard.vue";
import statsService from "../../../services/stats.service";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";

export default {
  name: "WorstSites",
  components: {
    MessageCard,
  },
  mounted() {
    // get period from query
    this.period = this.$route.query.period || "month";
    this.getWorstSites();
  },
  data() {
    return {
      loading: true,
      worstSites: [],
      period: "month",
      difference: 0,
      exporting: false,
    };
  },
  methods: {
    getWorstSites() {
      let diffObject = {};
      if (this.period == "lastyear") {
        this.period = "year";
        this.difference = -1;
      }
      diffObject[this.period] = this.difference;
      let start_date = DateTime.local()
        .startOf("month")
        .plus(diffObject)
        .startOf("day")
        .toFormat("yyyy-MM-dd");
      let end_date = DateTime.local()
        .endOf("month")
        .plus(diffObject)
        .endOf("day")
        .toFormat("yyyy-MM-dd");
      if (this.period == "day") {
        start_date = DateTime.local()
          .startOf("day")
          .plus(diffObject)
          .toFormat("yyyy-MM-dd");
        end_date = DateTime.local()
          .endOf("day")
          .plus(diffObject)
          .toFormat("yyyy-MM-dd");
      }
      if (this.period == "week") {
        start_date = DateTime.local()
          .startOf("week")
          .plus(diffObject)
          .startOf("day")
          .toFormat("yyyy-MM-dd");
        end_date = DateTime.local()
          .endOf("week")
          .plus(diffObject)
          .endOf("day")
          .toFormat("yyyy-MM-dd");
      }
      if (this.period == "quarter") {
        start_date = DateTime.local()
          .startOf("quarter")
          .plus(diffObject)
          .startOf("day")
          .toFormat("yyyy-MM-dd");
        end_date = DateTime.local()
          .endOf("quarter")
          .plus(diffObject)
          .endOf("day")
          .toFormat("yyyy-MM-dd");
      }
      if (this.period == "year") {
        start_date = DateTime.local()
          .startOf("year")
          .plus(diffObject)
          .startOf("day")
          .toFormat("yyyy-MM-dd");
        end_date = DateTime.local()
          .endOf("year")
          .plus(diffObject)
          .endOf("day")
          .toFormat("yyyy-MM-dd");
      }
      if (this.period == "lastyear") {
        start_date = DateTime.local()
          .startOf("year")
          .plus(diffObject)
          .startOf("day")
          .toFormat("yyyy-MM-dd");
        end_date = DateTime.local()
          .endOf("year")
          .plus(diffObject)
          .endOf("day")
          .toFormat("yyyy-MM-dd");
      }
      this.loading = true;
      statsService
        .getWorstSites(
          this.$store.state.auth.user.selectedClient,
          start_date,
          end_date
        )
        .then((response) => {
          this.worstSites = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    changeDifference(diff) {
      this.difference += diff;
      this.getWorstSites();
    },
    exportReport() {
      this.exporting = true;
      var data = [];
      for (let site of this.worstSites) {
        data.push({
          "Site Reference": site.siteReference,
          Name: site.name.charAt(0).toUpperCase() + site.name.slice(1),
          "Money Lost": site.lost.toLocaleString(),
          "Worst Pump": site.worstPump,
          "Money Recovered": site.paid.toLocaleString(),
        });
      }

      //Extract Data (create a workbook object from the table)
      // let workbook = XLSX.utils.table_to_book(table);
      let workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Worst Sites Report");

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, "WorstSitesReport.xlsx");
      this.exporting = false;
    },
  },
  computed: {
    title: function () {
      let diffObject = {};
      diffObject[this.period] = this.difference;
      switch (this.period) {
        case "day":
          return DateTime.local().plus(diffObject).toFormat("dd LLL yyyy");
        case "week":
          return (
            DateTime.local()
              .plus(diffObject)
              .startOf("week")
              .toFormat("dd LLL yyyy") +
            " - " +
            DateTime.local()
              .plus(diffObject)
              .endOf("week")
              .toFormat("dd LLL yyyy")
          );
        case "month":
          return DateTime.local().plus(diffObject).toFormat("LLLL yyyy");
        case "quarter":
          return (
            DateTime.local()
              .plus(diffObject)
              .startOf("quarter")
              .toFormat("dd LLL yyyy") +
            " - " +
            DateTime.local()
              .plus(diffObject)
              .endOf("quarter")
              .toFormat("dd LLL yyyy")
          );
        case "year":
          return DateTime.local().plus(diffObject).toFormat("yyyy");
      }
    },
  },
};
</script>

<style scoped>
.sub-title {
  font-weight: 300;
}
</style>
